import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import blog from "../../Assets/Projects/blog.jpg";
import boardgame from "../../Assets/Projects/boardgame.png";
import restore from "../../Assets/Projects/restore.png";
import weatherapp from "../../Assets/Projects/weatherapp.webp";
import lilly58 from "../../Assets/Projects/lilly58.jpeg";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works</strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={weatherapp}
              isBlog={false}
              title="Weather Module"
              description="Building an IP-address-based weather terminal display to be added into Fastfetch. This project serves as an educational experiment focusing on API usage, terminal environmental variables, and contributing to an open-source project."
              ghLink="https://github.com/Neuron89/weather_app"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={lilly58}
              isBlog={false}
              title="Custom Split Keyboard"
              description="Focusing on better typing habits and dissatisfied with the keyboards of my father's generation, I set out to create something that worked for my needs. I successfully built my own split keyboard, and I'm now exploring PCB design."
              ghLink="https://github.com/Neuron89/Custom-Keyboard-Builds"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={restore}
              isBlog={false}
              title="Backup and Restore"
              description="As a fan of simplicity, I'm developing this work-in-progress project. The goal is to create multiple small modules that work together to take full backups of your local computer, store them in your preferred location, and restore them with a single script. I plan to expand this into a bootloader-compatible solution that can also be used with Ansible to build new servers in my home lab."
              ghLink="https://github.com/Neuron89/Restore_project"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={blog}
              isBlog={false}
              title="Website from Scratch"
              description="While web development isn't my career focus, I firmly believe in understanding technologies by building them myself. This mindset has given me a deeper appreciation for others' talents. Following this principle, I challenged myself to create my own website from scratch, implementing multiple features including a blog server and a file-sharing section with OAuth integration."
              ghLink="https://github.com/Neuron89/website"
            />
          </Col>
          
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={boardgame}
              isBlog={false}
              title="BoardGame Database"
              description="My friends and I enjoy getting together to play board games from time to time. As we have all added a fairly large number to our collections, it has become difficult to track who owns what. I decided to start working on a project using React to create a website that hosts an SQL database and provides a list of everyone's games. The purpose is to enable quick and easy searching of our 'group collection' to prevent duplicate purchases and help plan our board game nights."
              ghLink="https://github.com/Neuron89/boardgame_database"
            />
          </Col>

        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
